<template>
  <section>
    <slot />
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
section {
  margin: 5rem auto;
  margin: 8vh auto;
}
</style>
