<template>
  <div class="github-stats">
    <h3 class="text-primary">
      <Tag tag="h3">GitHub Profile Statistics</Tag>
    </h3>
    <p>
      <Tag tag="p">
        These statistics represent my GitHub account and were generated by an open-source
        program called
        <a href="https://github.com/jstrieb/github-stats" target="_blank">github-stats</a
        >. The stats are updated every day and provide an overview of my GitHub
        contributions.
      </Tag>
    </p>
    <p>
      <Tag tag="p">
        My GitHub profile can be found at
        <a href="https://github.com/markcummins?tab=overview" target="_blank"
          >www.github.com/markcummins</a
        >. I also have a secondary work GitHub account which I also commit code to, so
        unfortunately the statistics here aren't fully up-to-date.
      </Tag>
    </p>
    <div ref="root" class="github-stat-cards">
      <div>
        <img
          :src="`https://raw.githubusercontent.com/markcummins/statistics/654e7273bacf2e66f69a66fb533880d0c603bbf0/generated/languages.svg?${version}#gh-dark-mode-only`"
        />
        <img
          :src="`https://raw.githubusercontent.com/markcummins/statistics/654e7273bacf2e66f69a66fb533880d0c603bbf0/generated/overview.svg?${version}#gh-dark-mode-only`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Tag from "@/components/layout/tag.vue";

export default {
  name: "component-github-stats",
  components: {
    Tag,
  },

  setup() {
    gsap.registerPlugin(ScrollTrigger);

    const root = ref(null);
    const version = ref(null);

    onMounted(() => {
      ScrollTrigger.create({
        id: "destroy",
        trigger: root.value,
        markers: false,
        start: "top bottom",
        onLeaveBack: () => {
          version.value = Date.now();
        },
      });

      ScrollTrigger.create({
        id: "animate",
        trigger: root.value,
        markers: false,
        start: "top 80%",
        onEnter: () => {
          version.value = Date.now();
        },
      });
    });

    return {
      root,
      version,
    };
  },
};
</script>

<style scoped lang="scss">
.github-stats {
  margin: 3rem auto;

  h3 {
    text-align: center;
    margin-bottom: 1rem;
  }
  p {
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .github-stat-cards {
    display: flex;
    justify-content: center;
    // max-width: 768px;
    > div {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 1rem;
    }
  }
  @media (min-width: 768px) {
    .github-stat-cards {
      > div {
        grid-column-gap: 1rem;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}
</style>
